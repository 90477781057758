<template>
  <div class="cpt-MMC_Gimbal_FF6">
    <div class="hd" v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">飞鹰喷火器</div>
      </div>
      <div @click="$emit('close')" class="close">关闭</div>
    </div>
    <div class="bd">
       <div class="rocker-wrap">
        <div class="title-box">
        </div>
        <div class="rocker" ref="rocker">
          <div
            class="shangUp"
            @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="xaiUp"
            @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="zuoUp"
            @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="youUp"
            @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
        </div>
      </div>
      <div class="form-wrap ml80">
        <div class="form-item">
          <div class="label-box">保险</div>
          <div class="input-box">
            <el-switch v-model="safety_switch_state" size="mini"></el-switch>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">喷火</div>
          <div class="input-box">
            <el-switch
              @change="handle_fire_ctrl"
              v-model="fire_status"
              size="mini"
            ></el-switch>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">回中</div>
          <div class="input-box">
            <el-switch
              @change="handle_center"
              v-model="center_status"
              size="mini"
            ></el-switch>
          </div>
        </div>
      </div>
     
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_FF6 } from "../utils";
export default {
  data() {
    return {
      timer: null,
      radio: 1,
      safety_switch_state: true,
      fire_status: false,
      center_status: false,
      time: null,
    };
  },
  methods: {
    handle_fire_ctrl(value) {
      if (this.safety_switch_state) {
        this.$message.warning("请先关闭保险");
        return (this.fire_status = false);
      }
      let buff = MMC_Gimbal_FF6.fire_ctrl(Number(value));
      this.commit_directive(buff);
    },
    handle_center(val) {
      if (val) {
        let buff = MMC_Gimbal_FF6.gimbal_mode3_ctrl(2);
        this.commit_directive(buff);
      } else {
        let buff = MMC_Gimbal_FF6.gimbal_mode3_ctrl(0);
        this.commit_directive(buff);
      }
    },
    commit_directive(buffer) {
      // console.log(buffer);
      this.$emit("directive", buffer);
    },
    stopfxFn() {
      this.flag = false;
      clearTimeout(this.time);
      let buffer = null;
      buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
      this.commit_directive(buffer);
      buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
      this.commit_directive(buffer);
    },
    fangxiang(type) {
      clearTimeout(this.time);
      let buffer = null;
      this.flag = true;
      if (this.flag) {
        this.time = setTimeout(() => {
          this.fangxiang(type);
        }, 500);
      }
      switch (type) {
        case "up":
          buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
          this.commit_directive(buffer);
          setTimeout(() => {
            buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(1);
            this.commit_directive(buffer);
          }, 50);

          break;
        case "right":
          buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
          this.commit_directive(buffer);
          setTimeout(() => {
            buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(1);
            this.commit_directive(buffer);
          }, 50);
          break;
        case "left":
          buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
          this.commit_directive(buffer);
          setTimeout(() => {
            buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(-1);
            this.commit_directive(buffer);
          }, 50);
          break;
        case "down":
          buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
          this.commit_directive(buffer);
          setTimeout(() => {
            buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(-1);
            this.commit_directive(buffer);
          }, 50);
      }
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
        this.commit_directive(buffer);
        buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
        this.commit_directive(buffer);
      }, 500);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case "up":
              buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
              this.commit_directive(buffer);
              setTimeout(() => {
                buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(1);
                this.commit_directive(buffer);
              }, 50);

              break;
            case "right":
              buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
              this.commit_directive(buffer);
              setTimeout(() => {
                buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(-1);
                this.commit_directive(buffer);
              }, 50);
              break;
            case "left":
              buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
              this.commit_directive(buffer);
              setTimeout(() => {
                buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(1);
                this.commit_directive(buffer);
              }, 50);
              break;
            case "down":
              buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
              this.commit_directive(buffer);
              setTimeout(() => {
                buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(-1);
                this.commit_directive(buffer);
              }, 50);
          }
        },
        onend: () => {
          buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
          this.commit_directive(buffer);
          buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
          this.commit_directive(buffer);
        },
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_FF6 {
  position: absolute;
  // top: 300px;
  right: 0px !important;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  width: 470px;
  height: 290px;
  box-sizing: border-box;
  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    height: 230px;

    overflow-y: auto;
    overflow-x: hidden;
    .form-wrap {
      min-width: 245px;
      flex-shrink: 0;
      .form-item {
        display: flex;
        align-items: center;
        height: 40px;
        .label-box {
          color: #cbd0eb;
          width: 75px;
        }
        .input-box {
          display: flex;
          align-items: center;
          .icon-box {
            [class^="el-icon-"] {
              font-size: 30px;
              color: #dce9ff;
            }
          }
        }
      }
    }
    .rocker-wrap {
      flex: 1;
      .title-box {
        .title {
          color: #cbd0eb;
        }
      }
      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;
        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;
    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }
    }
  }
  .el-input {
    width: 165px;
    margin-right: 5px;
    .el-input__inner {
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }
  }
  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #08c2d1;
            background: #08c2d1;
          }
        }
      }
      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }
  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}
</style>
